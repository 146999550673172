import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope} from "../../Components/Icon/icon";

 //style
 import "./Footer.css";

 const Footer = () => {
    return (
        <footer className="py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>DesignCave</h4>
                        <ul className="list-unstyled">
                            <li>About Us</li>
                            <li>FAQs</li>
                            <li>Our Team</li>
                            <li>Terms and Conditions</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>Quick Links</h4>
                        <ul className="list-unstyled">
                            <li>Socials</li>
                            <li>Help Center</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>Newsletter Signup</h4>
                        <form className="form-inline my-2 my-lg-0 ml-auto d-flex">
                        <input type="youremail" className="form-control mr-sm-2" placeholder="Your Email" aria-label="Search" />
                        <button className="btn btn-custom my-2 my-sm-0" type="submit">Subscribe</button>
                    </form>
                    </div>
                <div className='below d-flex align-items-center mt-5'>
                <div className="below_text col-md-4">
                    <h4>EMAIL SUPPORT</h4>
                        <div className='icon_text'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a href="mailto:designcavenigeria@gmail.com">
                        <p>Designcavenigeria@gmail.com</p></a>
                    </div>
                    </div>
                 <div className='below_text col-md-4'>
                 <h4>PHONE SUPPORT</h4>
                        <div className='icon_text'>
                    <p>+234 91 344 24 235</p>
                    </div>
                    </div>
                    <div className='below_text col-md-4'>
                    <h4>Connect With Us</h4>
                    <div className='socials gap-4'>
                       <a href='https://x.com/DesignCaveNGO'> <p>Twitter</p></a>
                       <a href='https://www.linkedin.com/company/designcavengo/'> <p>LinkedIn</p></a>

                    </div>
                        {/* <div className='icon_text'>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                    </a> */}
                    {/* </div> */}
                    </div>
                </div>
                </div>
            </div>
        </footer>
    );
};


export default Footer