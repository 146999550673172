import React from "react";
import FAQ from "../../Components/FAQ/FAQ";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import vector0 from  "../../Assets/Vector - 0.svg";
import frame from "../../Assets/frame.svg";
import career from "../../Assets/career.svg";
import community from "../../Assets/community.svg";
import practical from "../../Assets/practical.svg";
import prcticalcur from "../../Assets/practicalcur.svg";
import intro from "../../Assets/intro.svg";
import fundamental from "../../Assets/fundamental.svg";
import usercentered from "../../Assets/usercentered.svg";
import unionImage from "../../Assets/unionImage.svg";

 //style
 import "./HomePage.css";

function HomePage() {
  console.log("Home Page");
  return (
    <div className="container-fluid">
      <div className="row">

         <Header />
          <div className="home ">
            <div className="hero align-items-center justify-content-center">
              {/* <div className="hero_img">
              <img src={unionImage} alt="Img" />
              </div> */}
              <div
              className="hero align-items-center justify-content-center mt-5"
              style={{ backgroundImage: `url(${unionImage})` }}
            >
              <div className="hero_text p-3">
              {/* <i><h1 className="mt-1">Grow Your Knowledge In <h1> Product Design </h1></h1></i>
              <p className="mt-3">
              Our program is dedicated to enlightening minds, debunking myths, and guiding you through the essentials of UI/UX design
              </p>
              <p>Unlock the insights that help you make informed career decisions in product design.</p> */}

          <i>
            <h1 className="mt-1">
              Join Our Slack Community for Product Design Insights!
            </h1>
          </i>
          <p className="mt-3">
            We're building a vibrant community of product designers where you can connect, learn, and share your experiences.
          </p>
          <p>
            Whether you're just starting or an experienced designer, our Slack community is a great place to get the support you need, ask questions, and get direct feedback from industry experts. 
            <strong>Join us now and grow together!</strong>
          </p>
          <p>
            {/* <a href="https://join.slack.com/t/yourworkspace/signup" className="cta-button">
              Join the Slack Community
            </a> */}
          </p>
              <a href="#">
                <button id="start">Join Us</button>
              </a>
              </div>
              </div>
            </div>
            <div className="why align-items-center justify-content-center mt-5">
              <h1>Why DesignCave</h1>
              <p className="mt-3">
                At DesignCave, we believe that great design should be accessible
                to everyone. Our program offers a comprehensive introduction to
                the field of product design, helping you understand if this
                career path is right for you before you commit your time and
                resources to boot camps or advanced training. </p> <p>While our program
                is currently free, our mission is to provide valuable insights
                and support to help you make informed decisions about your
                future in design. If you’re passionate about design and ready to
                put in the work, join our community and start your journey
                toward becoming a skilled product designer.
              </p>
            </div>
            <div className="why_corner mt-5 d-flex flex-wrap justify-content-between">
              <div className="why_box p-3 align-items-center">
              <img src={vector0} alt="Icon" />
                <h1>No cost</h1>
                <p>Our program is free</p>
              </div>
              <div className="why_box p-3 align-items-center">
              <img src={frame} alt="Icon" />
                <h1>Portfolio focused</h1>
                <p>Create a robust portfolio that showcases your skills.</p>
              </div>
              <div className="why_box p-3 align-items-center">
              <img src={career} alt="Icon" />
                <h1>Career support</h1>
                <p>Get help with your resume, LinkedIn, and portfolio.</p>
              </div>
              <div className="why_box p-3 align-items-center">
              <img src={community} alt="Icon" />
                <h1>Community-driven learning</h1>
                <p>
                  Join a diverse and inclusive community of designers.
                </p>
              </div>
              <div className="why_box p-3 align-items-center">
              <img src={practical} alt="Icon" />
                <h1>Practical skills</h1>
                <p>
                  Learn the tools and techniques used in today's design jobs.
                </p>
              </div>
            </div>
            <div className="program mt-5 mb-5">
                <h1>Program Overview</h1>
                <p>Our program is designed as an introductory course to the world of Product Design. We focus on providing basic knowledge and skills, as well as career guidance, to help you make informed decisions about your design journey before investing in advanced training. The program is structured to give you a taste of what it's like to work as a Product Designer, and to help you assess your passion and potential in this field.</p>
                </div>
                <div className="ready mt-5 mb-4 text-center d-block">
                    <h1>Ready to level up your design skills</h1>
                    <p>Live classes starts September 17th to October 10th, 2024.</p>
                    <a href="https://forms.gle/61nRm3QmzGHi1puA6"><button className="ready text-center" id="join">Join Us</button>
                    </a>
                </div>

            <div className="curriculum mt-5 mb-3">
                <h1>Curriculum</h1>
                <div className="curriculum_box d-flex flex-wrap mt-3 justify-content-between">
                    <div className="box">
                    <img src={intro} alt="Img" />
                        <p>Introduction to UI/UX</p>
                    </div>
                    <div className="box">
                    <img src={fundamental} alt="Img" />
                        <p>Fundamentals of Figma</p>
                    </div>
                    <div className="box">
                    <img src={usercentered} alt="Img" />
                        <p>User- Centered Design</p>
                    </div>
                    <div className="box">
                    <img src={prcticalcur} alt="Img" />
                        <p>Practical Application</p>
                    </div>
                </div>
            </div>
            
            <FAQ />
            </div>
            <Footer/>
          </div>

      </div>
  );
}

export default HomePage;
